"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthRoute = void 0;
exports.AuthRoute = {
    fetch: '/auth/fetch',
    start: '/auth/start',
    redirect: '/auth/redirect',
    complete: '/auth/complete',
    signOut: '/auth/signout',
};
